import React from "react";
import Page from "../components/Page";
import { graphql, Link } from "gatsby";
import BlogCard from "../components/Blog/BlogCard";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Index({ data }) {
  const { posts } = data.blog;

  return (
    <>
      <Page
        title={"Studentmottagningen - Allt för studenten"}
        description="Studenternas samlingsplats och kompletta guide för att skapa en minnesvärd och rolig studentmottagning."
      >
        <section className="bg-white ">
          <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
            <div className="mr-auto place-self-center lg:col-span-7">
              <h1 className="max-w-2xl mb-4 text-5xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl ">
                Den kompletta guiden för studenten
              </h1>
              <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl ">
                Studenternas samlingsplats och kompletta guide för att skapa en
                minnesvärd och rolig studentmottagning.
              </p>

              <a
                href="/studentguide/"
                className="inline-flex px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100  "
              >
                Läs Guiden
              </a>
            </div>
            <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
              <GatsbyImage
                image={data.image.childImageSharp.gatsbyImageData}
                alt="studenter som firar"
              />
            </div>
          </div>
        </section>
        <section className="bg-white ">
          <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
            <dl className="grid max-w-screen-md gap-8 mx-auto text-gray-900 sm:grid-cols-3 ">
              <div className="flex flex-col items-center justify-center">
                <dt className="mb-2 text-3xl md:text-4xl font-extrabold">3K+</dt>
                <dd className="font-light text-gray-500 ">
                  läsare
                </dd>
              </div>
              <div className="flex flex-col items-center justify-center">
                <dt className="mb-2 text-3xl md:text-4xl font-extrabold">30+</dt>
                <dd className="font-light text-gray-500 ">
                  studentguider
                </dd>
              </div>
              <div className="flex flex-col items-center justify-center">
                <dt className="mb-2 text-3xl md:text-4xl font-extrabold">50+</dt>
                <dd className="font-light text-gray-500 ">
                  butiker
                </dd>
              </div>
            </dl>
          </div>
        </section>

        <section className="bg-white ">
          <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
            <GatsbyImage
              image={data.image2.childImageSharp.gatsbyImageData}
              alt="studenten"
              className="w-full rounded-lg"
            />
            <div className="mt-4 md:mt-0">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">
                Guider och tips för att skapa en minnesvärd studentfest
              </h2>
              <p className="mb-6 font-light text-gray-500 md:text-lg ">
                Vi hjälper dig att skapa en minnesvärd studentfest. Vi har
                samlat guider och tips från tidigare studentmottagningar och
                studentfester för att hjälpa dig att göra ditt evenemang
                framgångsrikt.
              </p>
            </div>
          </div>
        </section>
        <section className="bg-gray-50 ">
          <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
            <div className="max-w-screen-md mb-8 lg:mb-16">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">Allt för ditt kommande studentfirande</h2>
              <p className="text-gray-500 sm:text-xl ">Här på studentmottagningen hittar du allt för att planera studentfirandet, alltifrån studentmottagningen, studentflak till champangefrukost.</p>
            </div>
            <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
              <div>
                <h3 className="mb-2 text-xl font-bold ">Studentmottagning</h3>
                <p className="text-gray-500 ">Planera allt för din studentmottagning, från mat och dryck till dekorartioner för att ni ska få en minnevärd studentfest.</p>
              </div>
              <div>

                <h3 className="mb-2 text-xl font-bold ">Champangefrukost</h3>
                <p className="text-gray-500 ">Få tips på vilken mat som ni ska servera på er champangefrukost och allt som ni behöver veta för att starta dagen på bästa sätt.</p>
              </div>
              <div>

                <h3 className="mb-2 text-xl font-bold ">Studentflak</h3>
                <p className="text-gray-500 ">Följ vår guide hur ni hittar rätt studentflak och få tips och tricks på saker som är bra att tänka på inför ert studentflak.</p>
              </div>
              <div>

                <h3 className="mb-2 text-xl font-bold ">Studentmössa</h3>
                <p className="text-gray-500 ">Lär dig mer om det som du behöver veta när du ska köpa en studentmössa, alltifrån färgen på studentmössan till vad du kan skriva på den.</p>
              </div>
              <div>

                <h3 className="mb-2 text-xl font-bold ">Studentplakat</h3>
                <p className="text-gray-500 ">Ta reda på hur du köper ett studentplakat och vilket alternativ som du borde välja för din tjej eller kille som tar studenten.</p>
              </div>
              <div>

                <h3 className="mb-2 text-xl font-bold ">Studentpresent</h3>
                <p className="text-gray-500 ">Få massor av tips och inspriation på personliga och spännande studentpresenter för att du det som passar bäst.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white ">
          <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">Jämför och hitta det bästa alternativet</h2>
              <p className="mb-5 font-light text-gray-500 sm:text-xl ">Följ någon av våra köpguider för att hitta det bästa alternativet för dig, oavsett om det gäller studentskylt, studentmössa eller studentpresent.</p>
            </div>
            <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
            <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow xl:p-8  ">
                <h3 className="mb-4 text-2xl font-semibold">Studentplakat</h3>
                <p className="font-light text-gray-500 sm:text-lg  mb-4">Jamför studentskyltar och hitta det bästa alternativet för dig.</p>
                <ul role="list" className="mb-8 space-y-4 text-left">
                  <li className="flex items-center space-x-3">
                    <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                    <span>Jämför priser</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                    <span>Välj en egen bild</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                    <span>Snabba leveranser</span>
                  </li>
              
                </ul>
                <Link to="/studentskylt/" className="text-white bg-emerald-600 hover:bg-emerald-700 focus:ring-4 focus:ring-emerald-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center   ">Jämför studentplakat</Link>
              </div>
              <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow xl:p-8  ">
                <h3 className="mb-4 text-2xl font-semibold">Studentmössa</h3>
                <p className="font-light text-gray-500 sm:text-lg  mb-4">Jämför de olika mössbolagen och hitta studentmössan för dig.</p>
                <ul role="list" className="mb-8 space-y-4 text-left">
                  <li className="flex items-center space-x-3">
                    <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                    <span>Jämför priser</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                    <span>Hög kvalité</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                    <span>Snabba leveranser</span>
                  </li>
              
                </ul>
                <Link to="/studentmossa/" className="text-white bg-emerald-600 hover:bg-emerald-700 focus:ring-4 focus:ring-emerald-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center   ">Jämför studentmössor</Link>
              </div>
              <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow xl:p-8  ">
                <h3 className="mb-4 text-2xl font-semibold">Studentpresent</h3>
                <p className="font-light text-gray-500 sm:text-lg  mb-4">Få tips på roliga och personliga studentpresenter.</p>
                <ul role="list" className="mb-8 space-y-4 text-center">
                  <li className="flex items-center space-x-3">
                    <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                    <span>Bra priser</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                    <span>Presenter för alla</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                    <span>Snabba leveranser</span>
                  </li>
              
                </ul>
                <Link to="/studentpresent/" className="text-white bg-emerald-600 hover:bg-emerald-700 focus:ring-4 focus:ring-emerald-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center   ">Jämför studentpresenter</Link>
              </div>
             
            </div>
          </div>
        </section>
        <section className=" ">
          <div className="container px-4 py-8 mx-auto max-w-screen-xl">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">
              Senaste artiklar
            </h2>
            <div className="blog-grid">
              {posts &&
                posts.map((post) => {
                  return (
                    <BlogCard
                      key={post.id}
                      title={post.frontmatter.title}
                      description={post.frontmatter.description}
                      link={post.fields.slug}
                      image={post.frontmatter?.coverImage}
                    />
                  );
                })}
            </div>
          </div>

          <br />
          <br />
        </section>

      </Page>
    </>
  );
}

export const pageQuery = graphql`
  query home {
    blog: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/(/blog/)/" } }
      limit: 3
    ) {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          description
          coverImage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        fields {
          slug
        }
        id
        excerpt(pruneLength: 120)
      }
    }
    image: file(relativePath: { eq: "studentimage.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    image2: file(relativePath: { eq: "studenthattar.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
